/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css?family=Lato:300,400,900');
/* font-family: 'Lato', sans-serif; */

*{outline:none;}
#__lpform_input_idx_0{display:none !important}
h1, h2, h3, h4, h4, h6{font-weight: bold !important; color:#000 !important;}
main{position:relative;height: 100%;}

.main-wrapper{max-width:1010px;width:100%;margin:0 auto;position:relative;}

[role="main"]{position:relative; width: 100%; max-width: 100%;}

.main-header{margin:0;}
.main-header h2{margin:0;font-family: 'Lato', sans-serif;font-size:20px;color:#fff !important;font-weight:normal;background:#383839;padding:12px 30px;line-height: normal;}

.page-title{padding:45px 0 30px;font-family: 'Lato', sans-serif;font-size:28px;color:#000;font-weight:bold;line-height: normal;}

ul.nav-main li.nav-parent{cursor:pointer}

.margin-top-15{margin-top:15px !important}   
.margin-bottom-15{margin-bottom:15px !important}
.margin-left-15{margin-left:15px !important}
.margin-right-15{margin-right:15px !important}

.margin-top-20{margin-top:20px !important}   
.margin-bottom-20{margin-bottom:20px !important}
.margin-left-20{margin-left:20px !important}
.margin-right-20{margin-right:20px !important}

.margin-top-30{margin-top:30px !important}   
.margin-bottom-30{margin-bottom:30px !important}
.margin-left-30{margin-left:30px !important}
.margin-right-30{margin-right:30px !important}

.padding-top-30{padding-top:30px !important}   
.padding-bottom-30{padding-bottom:30px !important}
.padding-left-30{padding-left:30px !important}
.padding-right-30{padding-right:30px !important}

.margin-top-100{margin-top:100px !important}   
.margin-bottom-100{margin-bottom:100px !important}
.margin-left-100{margin-left:100px !important}
.margin-right-100{margin-right:100px !important}

.text-red{color:#e43346 !important;}
.text-default{color:#757575 !important;}
.text-blue{color:#27aee1 !important;}

.no-margin-top{margin-top:0 !important}   
.no-margin-bottom,
.input-group.no-margin-bottom{margin-bottom:0 !important}
.no-margin-left{margin-left:0 !important}
.no-margin-right{margin-right:0 !important}

.no-padding-top{padding-top:0 !important}   
.no-padding-bottom{padding-bottom:0 !important}
.no-padding-left{padding-left:0 !important}
.no-padding-right{padding-right:0 !important}

.no-border{border:none !important}

ul.nav-main li i{cursor: pointer;}

#login.body-sign .center-sign{padding:0;display:block;}
.page-header{margin: 0}
.page-header h2{    color: #333 !important; float:none}


.no-service .show-details{padding:0 !important;text-align:center;}

section .sign-in-logo{display:block;}
section#login{display: block;max-width: 460px; margin: 0 auto; height: auto;padding: 0;}
section#login img#logo{margin: 60px auto 45px;display: block;width:210px;}

section#login .myalltel-banner{}
section#login .myalltel-banner img{width:100%;}

section#login{}
section#login .signin-wrapper{background:#fff;border-radius: 8px;box-shadow: none;overflow: hidden;}
section#login .signin-wrapper .signin-banner{}
section#login .signin-wrapper .signin-banner img{width:100%}
section#login .signin-wrapper .signin-content{padding:30px 30px 40px;}
section#login .signin-wrapper .signin-content input{font-size:14px; color:#383839}
section#login .signin-wrapper .signin-content .form-group{margin-bottom:15px;}
section#login .signin-wrapper .signin-content h4{font-family: 'Lato', sans-serif;font-size:15px;font-weight:900;margin: 0;}
section#login .signin-wrapper .signin-content label{font-family: 'Lato', sans-serif;font-size:14px;font-weight:normal;margin: 0 0 15px;color:#757575}
section#login .signin-wrapper .signin-content .--remember{margin-bottom:30px;}
section#login .signin-wrapper .signin-content .--remember .checkbox-custom{position: relative;margin: 0;}
section#login .signin-wrapper .signin-content .--remember .checkbox-custom input[type="checkbox"]{position:absolute}
section#login .signin-wrapper .signin-content .--remember .checkbox-custom label{margin:0}
section#login .signin-wrapper .signin-footer{padding: 0 30px 25px;}

header.header-title{position: relative;background: #f6f6f6;padding: 20px 30px;line-height: normal;    border-bottom:none;}
header.header-title h2{color:#383839;font-size:20px;font-weight:bold;}
header.header-title img{position:absolute;right:30px;top:50%;transform:translateY(-50%) rotate(-90deg);cursor:pointer;transition:0.5s ease;transform-origin:center center;} 
header.header-title.__active img{transform:translateY(-50%) rotate(0);}

section.myalltel-section{margin-bottom:30px; box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04);}
section.myalltel-section .myalltel-form{padding:20px 30px 30px;}
section.myalltel-section .myalltel-form.--addons{padding:20px 30px 20px;}
section.myalltel-section .myalltel-form label{font-size: 14px;font-weight: bold;color: #000;padding-left: 15px;padding-bottom: 15px;line-height: normal;margin: 0;}
section.myalltel-section .myalltel-form .form-group{margin-bottom:30px;}

.myalltel-section.search-results-section{padding-top:45px;}
.myalltel-section.search-results-section header{padding: 20px 30px;}
.myalltel-section.search-results-section .services{padding:30px;}
.myalltel-section.search-results-section .form-control{}

/* REMOVE Border if has value  === STAND-BY ===
section.myalltel-section .myalltel-form input:not(:placeholder-shown) {border:1px solid rgba(255,255,255,0)}
section.myalltel-section .myalltel-form input:placeholder-shown {border: 1px solid #ced4da}*/

p.--notes{font-size:13px;color:#000;margin:0;line-height:normal;}
p.--notes strong{font-size:18px;line-height: normal;margin-bottom: 4px;display: inline-block;}
p.--notes span{display:block;}
.valign-center-left{position:absolute;top:50%;transform:translateY(-50%);left:0}
.valign-center-right{position:absolute;top:50%;transform:translateY(-50%);right:15px}

.action-group{position:relative;}
.action-group button{font-size:10px;}
.action-group.valign-center-right{right:15px;}
.action-group.valign-center-right .btn-blank{margin-left:15px;}
.action-icon{color: #979797 !important;font-size: 12px !important;margin-right: 5px;cursor: pointer;}
.action-btn{font-size: 8px;font-weight: bold;border: none;border-radius: 5px;line-height: normal;padding: 4px 5px;cursor: pointer;    width: 40px;}
.action-btn.action-addon{cursor:default}

.__addons{padding-top:20px;position:relative;}
.__addons:before{content: '';position: absolute;left: -15px;top: 0;height: 1px;width: calc(100% + 30px);background: #e9eaee;}

.__addons .addon-form input{width: 100%;border: 1px solid #dadada;border-radius: 4px;padding: 9px 15px;color: #757575;font-size: 14px; line-height:normal}

.__addons .addon-button{}

section.myalltel-section .myalltel-form .__addons .addon-form,
section.myalltel-section .myalltel-form .__addons .addon-button{margin-bottom:0}
section.myalltel-section .myalltel-form.--addons .form-group.col-md-8 {  max-width: 56%;}
section.myalltel-section .myalltel-form.--addons .form-group.col-md-4 { max-width: 44%;flex: unset;}

.with-label.form-control:disabled, .with-label.form-control[readonly]{background:#fff;position:relative;}

.add-label{position:relative}
.add-label:after{content: '';font-size: 8px;font-weight: normal;border: none;border-radius: 5px;line-height: normal;padding: 2px 5px 3px;cursor: pointer;position: absolute;top: 50%;transform: translateY(-50%);right: 15px;color: #fff;width:40px;text-align:center;}
.add-label-standard.add-label:after{content:'Free';background:#1c2949;}
.add-label-personal.add-label:after{content:'Add-on';background:#e43346;}

.myalltel-form-detail{padding:0 !important;}

.detail-row.form-group{margin:0;padding:0 30px;}
.detail-row.form-group .form-group{padding: 0 30px;margin: 30px 0;}
.detail-row.form-group .separator:before{content: '';position: absolute;top: 55%;right: 0;width: 1px;height:100%;background:rgba(182, 182, 182, 0.4);z-index: 10;display: block;transform: translateY(-50%);}
.detail-row.form-group.fullwidth-border{position:relative;padding:0}
.detail-row.form-group.fullwidth-border:before{content: '';position: absolute;top:50%;left:0;width: 100%;height: 1px;background: rgba(0, 0, 0, 0.04);z-index: 10;display: block;}

.detail-row label{color: #000;font-size: 16px;font-family: 'Lato', sans-serif;font-weight: bold;margin: 0;width: 100%;padding: 0;}
.detail-row input{padding:0;line-height:normal;border:none;color:#000;font-size:13px;}


.myalltel-form-middle.card-body{background: #f9f9f9;padding: 15px;} 
.myalltel-form-middle.card-body .middle-script-wrapper{background: #fff;padding: 0;margin-bottom:15px;}
.myalltel-form-middle.card-body .middle-script-wrapper p{margin:30px 25px}
.myalltel-form-middle.card-body .middle-script-wrapper .valign-center-right{right:45px;}
.myalltel-form-middle.card-body .middle-script-wrapper .added-field-group{display:flex;width:100%;padding:20px 30px;position:relative;}
.myalltel-form-middle.card-body .middle-script-wrapper .added-field-group .addon-form{margin-bottom:0;position:relative;}
.myalltel-form-middle.card-body .middle-script-wrapper .added-field-group .addon-form input{border: 1px solid #dadada;width: 100%;border-radius: 4px;padding: 10px 15px;font-size: 14px;font-weight: normal;color: #000;}
.myalltel-form-middle.card-body .middle-script-wrapper .added-field-group:before{content:'';box-shadow: 0 1px 4px 0 #00000033;position:absolute;top:0;left:15px;width:calc(100% - 30px);height:1px;}
.action-group.valign-right{position:absolute;top:50%;transform:translateY(-50%);right:15px;}

.myalltel-card-wrapper{margin-top:15px;padding-left: 7.5px; padding-right: 7.5px;}

.myalltel-card-wrapper .card{background: #fafafa;padding: 0;}
.myalltel-card-wrapper .card .card-header{padding:25px 10px;border-bottom: none;  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);background:#fafafa;z-index:10}
.myalltel-card-wrapper .card .card-header input{border:1px solid rgba(182, 182, 182, 0.4);font-size:13px;}
.myalltel-card-wrapper .card .card-body{padding:0;    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);position: relative;}
.myalltel-card-wrapper .card .card-body .btn-outline-secondary{    background-color: #27aee1; color: #fff; border: none;}
.myalltel-card-wrapper .card .card-body .btn-outline-secondary:hover, .myalltel-card-wrapper .card .card-body .btn-outline-secondary:active { background-color: #2692bf; }

.myalltel-card-wrapper .card .card-subbody .valign-center-right{    position: relative; transform: none;  padding: 17px 5px; top: 0;    right: 0;}
.myalltel-card-wrapper .card .card-subbody .valign-center-right button{    margin-right: 5px;}

.myalltel-card-wrapper .card .card-subbody .form-group{margin-bottom:0; border-bottom: 1px solid #e9eaee;}
.myalltel-card-wrapper .card .card-subbody .form-group p{margin:23px 0 23px 20px;}
.myalltel-card-wrapper .card .card-subbody .btn-outline-secondary { display: none;}

.myalltel-card-wrapper .--notes{margin-bottom:10px;}
.myalltel-card-wrapper .--notes strong{font-size:13px}
.myalltel-card-wrapper .--notes span{font-size:10px; font-family:'Lato',sans-serif}
.myalltel-card-wrapper .--notes span:first-child { font-size: 14px; font-weight: 900;}

.myalltel-card-wrapper .card-subbody.ng-star-inserted .form-group.col-md-6{padding: 0;  float: left; max-width: 50%;}
.myalltel-card-wrapper .card-subbody.ng-star-inserted .form-group.col-md-6:last-child{max-width: 50%; }
.myalltel-card-wrapper .card-subbody.ng-star-inserted:last-child .form-group.col-md-6 {   border-bottom: none;}
.myalltel-card-wrapper .card-subbody.ng-star-inserted .input-group > .input-group-prepend > .input-group-text#basic-addon1 { display: none;}
.myalltel-card-wrapper .card-subbody.ng-star-inserted .input-group > .form-control:not(:first-child){background-color: transparent;
    min-height: 0;
    font-family: Lato;
    font-size: 13px;
    color: #000;
    padding: 8px 10px 10px 0px;
    height: auto;
    line-height: normal;
    border: 1px solid transparent;
    border-radius: 4px;}
.myalltel-card-wrapper .card-subbody.ng-star-inserted .input-group > .form-control.active{border: 1px solid rgba(182, 182, 182, 0.4);}
.myalltel-card-wrapper .card-subbody.ng-star-inserted .input-group > .form-control:not(:first-child):focus{box-shadow:none}


.myalltel-card-wrapper .card-subbody-wrapper{max-height: 360px;min-height: 360px; overflow-y: scroll; overflow-x: hidden;background:#fafafa; }
.myalltel-card-wrapper .card-subbody-wrapper .card-subbody{background: #fff;margin-bottom: 5px;}
.myalltel-card-wrapper .card-subbody-wrapper .card-subbody .form-group{padding:15px 15px 10px}
::-webkit-scrollbar-track{background-color: transparent;}
::-webkit-scrollbar{width: 4px;background-color: transparent;}
::-webkit-scrollbar-thumb{border-radius: 2px;background-color: rgba(182, 182, 182, 0.8);}
.myalltel-card-wrapper p.empty-card-message{ position: absolute; left: 0; top: 60%;  width: 100%; text-align: center;}

.myalltel-form.ng-star-inserted select[name="greeting_type"], .myalltel-form.ng-star-inserted select[name="sign_off_type"] { background-color: #27aee1; color: #fff;}


/*== RESET BUTTONS == */
.myalltel-section.card .btn-info{/* background-color: #27aae1; border: none;font-family: 'Lato',sans-serif;     font-size: 15px;  padding: 10px 15px; line-height: normal; color:#fff */}
.myalltel-section.card .btn-info:hover, .myalltel-section.card .btn-info:active { background-color: #2692bf; color:#fff}
.myalltel-section.card .btn-info:not(:disabled):not(.disabled):active:focus, .myalltel-section.card .btn-info:not(:disabled):not(.disabled).active:focus, .show > .myalltel-section.card .btn-info.dropdown-toggle:focus{box-shadow:none;}
.myalltel-section.card .btn-info:not(:disabled):not(.disabled):active, .myalltel-section.card .btn-info:not(:disabled):not(.disabled).active, .myalltel-section.card .show > .btn-info.dropdown-toggle{    background-color: #27aee1; border-color: #27aee1;}
.myalltel-section.card .btn-info:focus, .myalltel-section.card .btn-info.focus{box-shadow:none}
.myalltel-section.card .myalltel-form-middle .btn-info { width: 245px;}
.myalltel-section .btn-light, .myalltel-section .btn-light:hover{background-color:#ececec;color:#000000}

.btn-blank{background:none;border:none;width:auto;margin:0;padding:3px 0;color:rgba(0,0,0,0.2)}
.btn-blank:hover{color:rgba(0,0,0,1)}
.card-body .btn-info:not(:disabled):not(.disabled):active, .card-body .btn-info:not(:disabled):not(.disabled).active, .card-body .show > .btn-info.dropdown-toggle, .btn-info {color: #fff;background-color: #27aee1 !important; border-color: #27aee1 !important;}
.btn-info:focus, .btn-info.focus{box-shadow: none !important;  outline: none;}
/*== SEARCH RESULTS DEFAULT == */
.search-results-section h1{font-size: 28px; font-weight: 900; margin: 0 0 25px;color: #000;font-family: 'Lato',sans-serif; line-height: normal;}
.search-results-section .card-title{font-size: 20px; color:#000;font-weight: 900; font-family: 'Lato',sans-serif; line-height: normal;}
.search-results-section .card-body {background-color: #f9f9f9;}
.search-results-section .card-body label{line-height: normal; font-family: Lato; font-size: 14px;font-weight: bold; color: #000000;margin: 0 0 15px;}
.search-results-section .search-results-details {width:100%; border-radius: 4px; box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04); border: solid 1px #e9eaee;  background-color: #ffffff; padding: 30px 15px;}
.search-results-section .search-results-details .show-details{font-size: 13px; font-weight: 900; color: #757575; padding: 0 15px 30px; display: block;font-family: 'Lato',sans-serif;}
.search-results-section .search-results-details .result-item{box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2); background-color: #ffffff; padding:15px;    margin: 0 auto 15px;}
.search-results-section .search-results-details .result-item{ font-family: Lato;}
.search-results-section .search-results-details .result-item .button-column{align-items: center; display: flex;    justify-content: flex-end;}
.search-results-section .search-results-details .result-item .button-column a{width:140px}
.search-results-section .search-results-details .result-item .button-column div { padding-left: 7.5px; padding-right: 7.5px;}
.search-results-section .search-results-details .result-item .service-id {font-family: Lato;font-size: 18px;font-weight: 900;color: #000;}
.search-results-section .search-results-details .result-item .category-name {font-size: 13px;font-weight: 400;color: #000;}
.search-results-section .search-results-details .result-item .status .action-btn{font-size: 10px;}
.search-results-section .search-results-details .result-item.false {display: none;}
.search-results-section .pagination-holder {padding-top: 15px;}
.search-results-section .pagination-holder .ngx-pagination{padding: 0;    margin: 0;}
.search-results-section .pagination-holder .ngx-pagination li { border-radius: 4px; border: solid 1px #dadada; background-color: #ffffff;  margin: 0;}
.search-results-section .pagination-holder .ngx-pagination li.pagination-previous {border-right: none; border-top-right-radius: 0; border-bottom-right-radius: 0;}
.search-results-section .pagination-holder .ngx-pagination li.pagination-next {border-left: none; border-top-left-radius: 0; border-bottom-left-radius: 0;}
.search-results-section .pagination-holder .ngx-pagination .current{background-color:#27aee1;     border-radius: 0;}
.search-results-section .pagination-holder .ngx-pagination .pagination-previous a::before, .search-results-section .pagination-holder .ngx-pagination .pagination-previous.disabled::before, .search-results-section .pagination-holder .ngx-pagination .pagination-next a::after, .search-results-section .pagination-holder .ngx-pagination .pagination-next.disabled::after{content:none}

.search-results-section.vr-history .search-by-date .col-md-9{max-width:73%}
.search-results-section.vr-history .search-by-date .button-column{align-items: center; display:flex; margin-top:15px;}
.search-results-section.vr-history .search-by-date .button-column div {padding-left:7.5px; padding-right:7.5px;}
.search-results-section.vr-history .search-by-date .button-column .col-md-6:first-child a {  width: 110px;}
.search-results-section.vr-history .search-by-date .button-column a i{margin-right:12px;}

#history-main-wrapper.card-body{background:#f9f9f9;padding:30px;}
.history-wrapper{background:#fff;padding:30px 30px 25px;margin-bottom: 30px;}
.history-wrapper .history-counter{color:#757575;font-family: 'Lato', sans-serif;font-size:13px;font-weight:bold;margin-bottom:15px;line-height:normal;}

.history-list{}
.history-list .history-row:nth-child(odd){background:#fff}
.history-list .history-row:nth-child(even){background:#f2f2f2}

.history-list .history-row{border:1px solid #e9eaee;padding:15px 0;}
.history-list .history-row .history-info{line-height: normal;padding:0 15px;}
.history-list .history-row .history-info strong{font-size:15px;color:#000;text-transform:capitalize;line-height:normal}
.history-list .history-row .history-info p{margin:0 0 7px;color:#000;font-size:13px;line-height:normal}
.history-list .history-row .history-info p span.caller{margin-left:15px;}
.history-list .history-row .history-info > span{font-size:13px;color:#757575;line-height:normal}

.history-list .history-row .history-info-date{line-height: normal;padding:0 15px;}


.__alternating.button{display:none !important;box-shadow:unset !important;}
.__alternating.active.button{display:inline-block !important;box-shadow:unset !important;}


.card-body.boiler-wrapper{padding:30px 0;background: #ecedf0;box-shadow:none;}
.boiler-wrapper .container.header-form{padding: 30px;box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);background-color: #fff; border-radius: 4px;   background-color: #f9f9f9}
.boiler-wrapper .header-form button{right:15px;}
.boiler-wrapper .header-form h4{margin:0 0 15px;}
.boiler-wrapper .header-form .button-col{display: flex;  align-items: center; justify-content: flex-end;}
.boiler-wrapper .container{max-width: 1010px;padding:0}

.boiler-wrap{padding:0;    max-width: 1010px !important; }
.boiler-wrap-row{padding:0;margin:0;}
.boiler-wrap-group{position:relative;display: flex;width: 100%;margin: 0 15px;background: #fff;padding: 20px 15px;flex-wrap: wrap;box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);}
.boiler-wrap-group .boiler-wrap-input{line-height: normal;position:relative; padding: 11px 15px;}
.boiler-wrap-group .boiler-wrap-input:before{position: absolute;content: '';right: 0;top: 5px;width: 1px;height: calc(100% - 10px);background: #e9eaee;}
.boiler-wrap-group .boiler-wrap-input.noline:before{display:none;}
.boiler-wrap .action-group.valign-center-right { left: 0; text-align: right;}
.boiler-wrap .action-group.valign-center-center {left:50%;top:50%;transform:translate(-50%,-50%);text-align:center}
.boiler-wrap-group .-note-addons{font-size: 12px;font-family: 'Lato', sans-serif;display: inline-block;margin-bottom: 5px;font-weight: bold;padding-left: 15px;line-height: normal; color:#e43346;}

.boiler-wrapper .nav-tabs li .nav-link{ color: #000; background-color: #f2f2f2; font-weight: bold; border-top: 3px solid transparent !important; border: none;  border-radius: 0;}
.boiler-wrapper .nav-tabs .nav-link.active, .boiler-wrapper .nav-tabs .nav-item.show .nav-link{    border-top: 3px solid #27aee1 !important;     background-color: #fff;}
.boiler-wrapper .nav-tabs li{    border-left: 1px solid #EEE;}
.boiler-wrapper .nav-tabs li .nav-link:hover{   border-top: 3px solid #27aee1 !important;     background-color: #fff; color:#000;    border: none;}
.flux-input{border: 1px solid transparent;border-radius: 4px;width: 100%;display: flex;}
.flux-input._active{border: 1px solid #dadada;}


.boiler-wrap-group input{border: none;outline: none !important;box-shadow: none !important;padding: 0;z-index:10;position:relative;background:none !important;width:80%;}

.-caption{display: block;font-family: 'Lato', sans-serif;font-size: 10px;color: #757575;font-weight: bold;width: 100%;}
.-caption.addon { color: #e43346;}
.pos-right{position:absolute;right:0;}
.boiler-field{}
.boiler-field .input-group{position:relative;}
.boiler-field .input-group:before{content:'';position:absolute;width:calc(100% + 20px);height:1px;background:#f5f5f5;bottom:0;left:-10px;}

.boiler-field input{width: 100%;padding: 7px;border: 0;font-size: 15px;color: #000;margin-top: 1px;}
.boiler-field::before{width:100%;}

.boiler-button{margin-top: 10px;line-height: normal;}
.boiler-field input.active{    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(0, 136, 204, 0.3);}

/*.boiler-border-right:before{content: '';top: 50%;right: 0;width: 1px;height: calc(100% - 10px);background: rgba(0,0,0,0.2);position: absolute;transform: translateY(-50%);} */

.toggle.ios, .toggle-on.ios, .toggle-off.ios { border-radius: 20px; }
.toggle.ios .toggle-handle { border-radius: 20px; }

._notification{position:fixed;top:100px; right:30px;z-index:999999;}
._notification [role="alert"]{padding: 20px;margin: 0;border:none;}
._notification .alert-success{background:#50a850}
._notification .alert-danger{background:#e43346;}
._notification .state-icon{border-radius: 50%;width: 55px;height: 55px;text-align: center;color: #ecedf0;border: 1px solid #ecedf0;display: inline-block;font-size: 40px;padding-top: 8px;position: absolute;top: 50%;transform: translateY(-50%);}

._notification p{margin: 0 0 0 55px;width: 210px;display: inline-block;vertical-align: top;padding-left: 15px;line-height: normal;color: #fff;font-size:15px;font-weight:bold;overflow-wrap: break-word;}
._notification-dialog-wrapper{ position: absolute; width: 100%; height: 100%; background: rgba(0,0,0,0.5); z-index: 999999;}
._notification-dialog{width: 460px;position: relative;z-index: 999999;background: #fff;border-radius: 4px;box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04);border: solid 1px rgb(233, 234, 238);margin: 60px auto;display: block;}
._notification-dialog .notif-container header{padding:20px 30px;font-family: Lato;font-size: 20px;font-weight: 900;line-height: normal;color: rgb(56, 56, 57);border-bottom:1px solid #ecedf0;}
._notification-dialog .notif-container .notif-content{padding:30px;}
._notification-dialog .notif-container .notif-content p{font-family: Lato;font-size: 14px;font-weight: normal;line-height: normal;color: rgb(117, 117, 117);margin:0;}
._notification-dialog .notif-container .notif-action{padding:0 30px 30px;}

.service-controllers{}
.service-controllers .serv-control-box{margin-bottom: 30px;}
.service-controllers .serv-control-box section{background:#fff; border: 2px solid transparent;min-height: 155px;position:relative;}

.service-controllers .serv-control-box section:hover { border: 2px solid #1c2949;}
.service-controllers .serv-control-box section header{padding:30px;background:#fff;cursor:pointer;}
.service-controllers .serv-control-box section header h2{margin-bottom: 15px;width: 100%;max-width: 100%;} 
.service-controllers .serv-control-box section header p{padding:0;line-height:normal;margin:0;width:210px;} 

.modal-content-slide{width: 100%;position: absolute;z-index: 1000;height: 100%;background: #ECEDF0;right: -100%;transition:0.6s ease;    overflow-y: scroll;}
.modal-content-slide._open{right:0;}

.modal-content-slide.fader{right:0 !important;transform-origin:center center;opacity:0;z-index:0}
.modal-content-slide.fader._open{opacity:1 !important;z-index:1000;overflow-x:scroll;}
.modal-content-slide .modal-content-wrap{padding:60px 0;}


.modal-control-close{background:#fff;box-shadow:0 2px 8px rgba(0, 0, 0, 0.1);padding:12px 25px;}
.modal-control-close > span{cursor: pointer;color: #27aee1;position: relative;font-size: 20px;font-weight: 900;font-family: Lato;display: inline-block;padding-left: 30px;}
.modal-control-close > span::before{background: url(/assets/images/arrow-chevron.svg) no-repeat center;display: block;content: '';width: 20px;height: 100%;position: absolute;transform: rotate(90deg);left: 0;}

.serv-block{padding: 30px 15px;width: 1010px;max-width: 1010px;margin: 45px auto 0;background:#f9f9f9;box-shadow: 0 2px 2px rgba(0,0,0,0.04);}

header.serv-block-name{padding:0 15px;font-family: Lato;font-size: 20px;font-weight: 900;color: #000;}

.serv-header-info{margin-top:15px;}


.serv-block .serv-miniblock{background: #fff;padding: 20px 15px;margin: 0;box-shadow: 0 2px 4px rgba(0,0,0,0.1);position: relative;z-index: 10;}
.serv-block .serv-miniblock .--notes{margin:0}
    
.serv-block .serv-sub-miniblock{background: #fff;padding: 20px 15px;margin: 0;box-shadow: 0 2px 4px rgba(0,0,0,0.1);}
.serv-block .serv-sub-miniblock label{font-size: 14px;font-weight: bold;line-height: normal;color: #000;margin-bottom:15px;}
.serv-block .serv-sub-miniblock label[for="greeting_message_after_hour"] { font-weight: normal;  color: #383839; font-size: 12px;}
.serv-block .serv-sub-miniblock  .text-counter { font-size: 10px;  color: #757575;  margin: 0 0 10px;display:inline-block}

.serv-block .serv-sub-miniblock input[type="checkbox"]:checked:before { top: -1px;}


span.placeholder-button{position: absolute;right: 0;top: 50%;-webkit-transform: translateY(-50%);
    transform: translateY(-50%);border-left: 1px solid #dadada;padding-left: 15px;}
span.placeholder-button button{padding:1px 10px;margin-right: 15px;font-size:12px;} 

.flux-field-group{border:1px solid #dadada;border-radius:4px;position:relative;padding:9px 15px;line-height:normal;margin:0 -15px;}
.flux-field-group.readonly{border-color:transparent;}
.flux-field-group._disabled{border:1px solid transparent;}
.flux-field-group input[type="text"]{border: none;padding: 0;font-size: 14px;font-weight: normal;line-height: normal;color: #757575;width: 80%;}
.flux-field-group input[type="text"][readonly]{box-shadow:none !important;background:#fff;color:#000 !important;}
.flux-field-group .text-counter{font-family: Lato;font-size: 10px;font-weight: normal;line-height: normal;color: #757575;}

::-webkit-input-placeholder {color:#000;}
:-ms-input-placeholder {color:#000;}
::placeholder {color:#000;}

.serv-select-field{border: 1px solid #dadada;padding: 10px 15px;border-radius: 4px;}
.serv-select-field label{margin: 0 15px 0 0;color: #e43346;font-size: 12px;line-height: normal;font-weight: 900;border-right: 1px solid #dadada;padding-right: 15px;}
.serv-select-field label span{}
.serv-select-field label.free{color: #27aee1}
.serv-select-field select{border: none;padding: 0;margin: 0;font-family: Lato;font-size: 13px;font-weight: normal;line-height: normal;color: #383839;}

.valign-middle{position:absolute;top:50%;transform:translateY(-50%);width: auto;right: 0;}


.container.serv-department{max-width:1010px;margin-top:45px;}
.serv-department-wrap{background: #f9f9f9;padding-bottom:20px;}
.serv-department-header{padding: 30px 0 0;background: #f9f9f9;display: flex;width: 100%;flex-wrap: wrap;}
.serv-department-header header{font-family: Lato;font-size: 20px;font-weight: 900;line-height: normal;color: #000;padding:0 30px;margin-bottom:15px;width:100%}
.serv-department-header .--notes{padding-left:15px;}
.serv-department-header .valign-center-right{right:30px;}

.serv-department-item.boiler-wrap {width: 100%;margin: 15px 15px 0;background: #fff;}
.serv-department-item.boiler-wrap .boiler-wrap-row{width: 100%;padding:0 0 15px;display: flex;flex-wrap: wrap;}
.serv-department-item.boiler-wrap .boiler-wrap-group{margin:0}
.serv-department-item.boiler-wrap .boiler-wrap-row .boiler-wrap-group{margin:0}

input[type="checkbox"] { box-sizing: border-box; padding: 0; width: 20px;  height: 20px;  background-color: #27aee1;cursor: pointer; border-radius: 2px}
input[type="checkbox"] + label {    font-size: 14px; font-weight: bold; color: #000; padding-left: 15px;    vertical-align: top; cursor:pointer;}
input[type="radio"] { background-color: #fff;-webkit-appearance: none; width: 13px; height: 13px; border-radius: 100%; position:relative !important;  border: solid 1px #dadada;}
input[type="radio"]:checked{background-color: #27aee1}
input[type="radio"]:checked:before {position: absolute;top: 50%;left: 50%;display: block;width: 4px;height: 4px;pointer-events: none;content: "";background-color: #fff;border-radius: 50%;transform: translate(-50%, -50%);}


.advance-config .tab-content{background-color: #fff;min-height: 415px;max-height: 415px;overflow-y: scroll;}
.advance-config .tab-content > .tab-pane{ background-color: #fafafa;}
.advance-config .tab-content > .tab-pane#ngb-tab-1-panel{}
.advance-config .tab-content > .tab-pane#ngb-tab-1-panel .form-check{align-items: center; height: 65px; box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2); background-color: #ffffff;  margin: 0;  width: 100%; display: flex;border-radius:4px;}
.advance-config .tab-content > .tab-pane#ngb-tab-1-panel .form-check.active{margin: 0 0 60px;}
.advance-config .tab-content > .tab-pane#ngb-tab-1-panel .form-check .btn-add{    font-size: 12px;  width: 58px; padding: 3px 10px; position: absolute;}
.advance-config .tab-content > .tab-pane#ngb-tab-1-panel .input-group .input-group{ position: absolute; margin: 0 !important; padding: 0 15px; bottom: 0;}
.advance-config button.update-config{ bottom: 15px;  display: block; width: 100%; max-width: 445px;}


/**SUMMARY**/
.summary  .services-summary-holder {border-radius: 4px;  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1); background-color: #ffffff; padding: 15px 30px 0;}
.summary .services-summary-holder h3{margin: 0; border-bottom: 1px solid rgba(182, 182, 182, 0.2);  padding-bottom: 15px; font-size: 16px;  line-height: normal;}
.summary .services-summary-holder .services-summary-item{ padding: 15px 0; border-bottom: 1px solid rgba(182, 182, 182, 0.2); margin: 0 auto;}
.summary .services-summary-holder .services-summary-item:last-child{border-bottom: none;}
.summary .services-summary-holder .services-summary-item div {padding: 0;}
.summary .services-summary-holder .services-summary-item .product-description, .summary .services-summary-holder .services-summary-item .product-amount {margin: 0; line-height: normal; font-size: 13px; font-weight: 900;color: #383839; font-family: 'Lato', sans-serif;}
.summary .services-summary-holder .services-summary-item .product-amount { text-align: right;}
.summary .total-summary-holder {padding:30px 0 0;}
.summary .total-summary-holder .col-md-7 {    border-right: solid 1px #e9eaee;}
.summary .total-summary-holder .amount-row p{ display: inline-block; vertical-align: top;  color: #757575;}
.summary .total-summary-holder .amount-row p:first-child{width: 75%;}
.summary .total-summary-holder .amount-row.additional-monthly p{  font-weight: 900; color:#383839;}
.summary .total-summary-holder .agree-save-btn{width: 360px;height: 38px; border-radius: 4px; background-color: #e43346;   font-size: 15px;  color: #ffffff; }
.summary .total-summary-holder .revert-btn{    font-size: 16px; line-height: normal; -webkit-appearance: none; background-color: transparent; border: solid 1px #dadada; border-radius: 4px;  width: 75px; height: 38px; cursor: pointer; color: #000; position: absolute;  bottom: 0;  left: 10px;}

.summary-wrapper{background:#ECEDF0;padding:0;margin:30px 0 0;}
.summary-wrapper .summary-container{padding: 30px;background: #f9f9f9;width: 100%;max-width: 1010px;margin: 0 auto;border-radius: 4px;box-shadow: 0 2px 2px rgba(0, 0, 0, 0.04);}
.summary-wrapper .summary-container > header{margin-left:-10px;font-family: Lato;font-size: 16px;font-weight: 900;line-height: normal;color: #383839;margin-bottom:30px;}
.summary-wrapper .summary-container .summary-column{background:#fff;height:100%;border-radius: 4px;box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);background-color: rgb(255, 255, 255);position:relative;}
.summary-wrapper .summary-container .summary-column {font-family: Lato;font-size: 16px;font-weight: 900;line-height: normal;color: rgb(56, 56, 57);padding:0;border-bottom:1px solid rgba(182, 182, 182, 0.2);overflow:hidden}
.summary-wrapper .summary-container .summary-column.__open{padding-bottom:46px;}

.summary-wrapper .summary-container .summary-column > header{font-family: Lato;font-size: 16px;font-weight: 900;line-height: normal;color: rgb(56, 56, 57);padding:15px 30px;border-bottom:1px solid rgba(182, 182, 182, 0.2);}

.summary-wrapper .summary-container .summary-column .services-summary-item{border-top:1px solid rgba(182, 182, 182, 0.2);margin:0 30px;position:relative}
.summary-wrapper .summary-container .summary-column .services-summary-item:nth-child(2){border-top:none}
.summary-wrapper .summary-container .summary-column .services-summary-item > div{text-align:center;}
.summary-wrapper .summary-container .summary-column .services-summary-item > div:first-child{padding-left:0;text-align:left;}
.summary-wrapper .summary-container .summary-column .services-summary-item.summary-label{border:none;margin:0;padding:0 30px;position:relative}
.summary-wrapper .summary-container .summary-column .services-summary-item.summary-label:before{content:"";position:absolute;bottom:-1px;left:0;width:100%;height:1px;background:rgba(182, 182, 182, 0.2);}
.summary-wrapper .summary-container .summary-column .services-summary-item.summary-label{border:none;margin:0;padding:0 30px;}
.summary-wrapper .summary-container .summary-column .services-summary-item p{font-family: Lato;font-size: 13px;font-weight: normal;line-height: normal;color: rgb(56, 56, 57);margin: 0;padding: 15px 0;text-transform: capitalize;}
.summary-wrapper .summary-container .summary-column .services-summary-item.summary-label p{font-weight: 900;font-size: 10px;}
.summary-wrapper .summary-container .summary-column .services-summary-item.summary-footer{border: none;background: #f9f9f9;margin: 0;padding: 0 30px;position: absolute;width: 100%;bottom: 0;
}
.summary-wrapper .summary-container .summary-column .services-summary-item.summary-footer p{font-weight: 900;font-size: 13px;}

.summary-wrapper .summary-container .summary-column .services-summary-item.no-changes{border: none;position: relative;width: 100%;padding: 0; margin: 0;text-align: center;background: #f9f9f9;min-height: 100%;display:block;}
.summary-wrapper .summary-container .summary-column .services-summary-item.no-changes .no-changes-holder{width: 100%;text-align: center;padding: 30px;position: relative;min-height: 100%;display: block;}
.summary-wrapper .summary-container .summary-column .services-summary-item.no-changes .no-changes-holder p{width: auto;display: block;padding: 0;position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);}

.total-summary-holder{margin-top:30px;}
.total-summary-holder p{font-family: Lato;font-size: 13px;font-weight: normal;line-height: normal;color: rgb(56, 56, 57);margin-bottom:30px}
.total-summary-holder .summary-actions .agree-save-btn{width:100%;background:#e43346;color:#fff;}

.serv-config{background: #fff;width: 100%;padding: 30px;display: flex;}
.serv-config .serv-config-column header{font-family: Lato;font-size: 16px;font-weight: 900;line-height: normal;color:#000;margin-bottom:15px;}
.serv-config .serv-config-column p.note{font-family: Lato;font-size: 13px;font-weight: normal;line-height: normal;color:#000;margin:0;padding:0;}
.serv-config .nav-tabs{margin-top:30px;}
.serv-config .nav-tabs .nav-item{width:50%;text-align:center;}
.serv-config .nav-tabs .nav-link.active, 
.serv-config .nav-tabs .nav-item.show .nav-link{}

.serv-config .nav-tabs li .nav-link, 
.serv-config .nav-tabs li .nav-link:hover{font-size: 14px;font-weight: 900;line-height: normal;text-align: center;color: rgb(0, 0, 0);padding:20px 0;}
.serv-config .nav-tabs li .nav-link.active {border-color: #27aae1 #dee2e6 #fff;}

.serv-config .serv-config-notif{width:100%;margin-top:30px;display:block;}
.serv-config .serv-config-notif label{width:100%;display:block;cursor:pointer;}
.serv-config .serv-config-notif label p{margin-bottom:15px;padding:0 15px;font-family: Lato;font-size: 14px;font-weight: bold;line-height: normal;color: rgb(0, 0, 0);}
.serv-config .serv-config-notif label input.form-control{font-family: Lato;font-size: 14px;font-weight: normal;line-height: normal;color: rgb(56, 56, 57);padding: 10px 15px;margin-bottom: 30px;}

.serv-config .serv-config-notif .serv-config-notif-sub{}
.serv-config .serv-config-notif .serv-config-notif-sub header{font-family: Lato;font-size: 14px;font-weight: bold; line-height: normal;color: rgb(0, 0, 0);}
.serv-config .serv-config-notif .serv-config-notif-sub p{font-family: Lato;font-size: 13px;font-weight: normal;  line-height: normal;color: rgb(0, 0, 0);margin-bottom:15px;}
.serv-config .serv-config-notif .serv-config-notif-sub input[type="radio"]{width:14px;height:14px;}


.spinner {
	border: 16px solid silver;
	border-top: 16px solid #337AB7;
	border-radius: 50%;
	width: 80px;
	height: 80px;
	animation: spin 700ms linear infinite;
	top: 50%;
	left: 50%;
	position: absolute;
}

@keyframes spin {
	0% {transform: rotate(0deg)}
	100% {transform: rotate(-360deg)}
}

.lds-hourglass {
	top: 0;
	left: 0;
  display: inline-block;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: rgba(0,0,0,0.1);
}
.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 6px;
  box-sizing: border-box;
  border: 26px solid #3e53cd;
  border-color: #3e53cd transparent #3e53cd transparent;
  animation: lds-hourglass 1.2s infinite;
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 99999;
}

@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

aside.sidebar-left.sidebar-left-collapsed {
    width: 73px;
}

aside.sidebar-left.sidebar-left-collapsed .nav-main a span, aside.sidebar-left.sidebar-left-collapsed .sidebar-header .sidebar-title span, aside.sidebar-left.sidebar-left-collapsed ul.nav-main li i.drop-down-arrow {
    visibility: hidden;
}

aside.sidebar-left.sidebar-left-collapsed .nav-main li > ul {
    display: none;
}

.department-config{
	margin-bottom: 15px;
}

ul.nav-main li i.drop-down-arrow {
    position: absolute;
    right: 20px;
}

ul.nav.nav-main li ul {
    padding-left: 40px;
}

ul.nav-main li ul li.active,  ul.nav-main li a.active{
	box-shadow: 2px 0 0 #0088cc inset;
}

ul.nav-main li a span, ul.nav-main li i{
    color: #383839;
}

ul.nav-main li a{
  background: #fafafa !important;
}

ul.nav-main li.active a,
ul.nav-main li a:focus,
ul.nav-main li a:hover{
	background: #F1F1F1 !important;
	color: #0088CC;
}

header.card-header div.col-2.download{
  margin: auto;
  text-align: -webkit-center;
}

.loader {
    border: 4px solid #d7d2d2;
    border-top: 4px solid #9f9a9a;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    /*position: absolute;*/
    /*text-align: center;*/
    top: 5px;
    left: calc(50% - 17px);
    margin: auto;
}

.loader-container {
  position: absolute;
  top: 10px;
  right: 10px;
  background: white;
}

.hardware-hub-img {
  width: 100%;
  -webkit-box-shadow: 1px 3px 11px 0px rgba(0,0,0,0.73); 
  box-shadow: 1px 3px 11px 0px rgba(0,0,0,0.73);
}

.loading-overlay-showing {
  overflow: hidden;
  /* position: absolute; */
  top: 0;
  left: 0;
  display: inline-block;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.3);
}

/* a .row.result-item.clearfix:hover {
  position: relative;
  top: -10px;
  transform: translateY(-5px);
  transition-timing-function: ease-out;
  transition-duration: .5s;
  -webkit-transition-property: transform;
  transition-property: transform;
}

a .row.result-item.clearfix {

  transition-timing-function: ease-out;
  transition-duration: .5s;
  -webkit-transition-property: transform;
  transition-property: transform;
} */

.panel-height {
  overflow: scroll;
  max-height: 288px;
  border: 1px solid #ECECEC ;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin: 0 auto 11px;
}

.panel-header-design {
  background: #1C2949 !important;
  border-radius: 5px;
  padding: 10px 22px 10px 22px !important;
  margin: 15px auto 0 !important;
}

.panel-title {
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: white;
}

.panel-service-length {
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: white;
}

.red {
  background-color: red;
}

.green {
  background-color: green;
}

.yellow {
  background-color: yellow;
}

.service-badge {
  height: 100%;
  margin-top: auto;
  margin-bottom: auto;
  margin: auto 0 auto 3px;
  border-radius: 18.25rem;
  padding: 0.35em 0.6em;
}

.service-category-name {
  font-size: 13px;
  font-weight: 400;
  color: #000;
}

.alias {
  border: 1px solid black;
}

.alias-label {
  margin: 0 !important;
  font-weight: bold !important;
}

.list-border {
  border: 1px solid #ECECEC;
}

.contact-content {
  border: unset;
  border-radius: unset;
  width: 400px;
  height: 380px;
}

.contact-body {
  text-align: center;
  padding: 46px 25.9px 31.6px 25px;
  font-family: Lato;
}

.pop-up-body {
  text-align: center;
  padding: 67px 62.9px 63px 46.3px;
  font-family: Lato;
}

.contact-title {
  width: 349.1px;
  height: 20px;
  font-size: 17px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;
  text-align: center;
  color: #383839;
  margin-bottom: 7.9px;
}

.pop-up-title {
  width: 349.1px;
  height: 20px;
  font-family: Lato;
  font-size: 17px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;
  text-align: center;
  color: #383839;
  margin-bottom: 7.9px;
  display: inline-block;
}

.contact-header-message {
  width: 349.1px;
  height: 20px;
  font-family: Lato;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #383839;
}

.pop-up-message {
  width: 290.1px;
  height: 77px;
  font-family: Lato;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #383839;
  display: inline-block;
  margin-top: 14.9px;
}

.contact-number {
  width: 340.1px;
  height: 63px;
  border-radius: 5px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  border-radius: 5px;
  padding: 21px;
  margin: auto;
  font-size: 15px;
  font-weight: 700;
  margin-top: 22.3px;
  background: #ffffff;
  border: unset;
}

.contact-chat {
  width: 340.1px;
  height: 63px;
  border-radius: 5px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  padding: 21px;
  margin: auto;
  font-size: 15px;
  font-weight: 700;
  margin-top: 25.3px;
  background: #ffffff;
  border: unset;
}

.pop-up-chat {
  width: 340.1px;
  height: 63px;
  border-radius: 5px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.32);
  background-color: #ffffff;
  padding: 21px;
  margin: auto;
  font-size: 15px;
  font-weight: 700;
  margin-top: 24.9px;
  background: #ffffff;
  border: unset;
  color: #27aae1 !important;
}

.pop-up-chat.disable-button {
  height: 34px;
  padding: 6px;
}

.contact-chat:hover img{
  filter: brightness(13);
}

.pop-up-chat:hover img{
  filter: brightness(13);
}

.contact-number:hover img{
  filter: brightness(13);
}

.contact-number:hover {
  background: #27aae1;
}

.pop-up-chat:hover {
  background: #27aae1;
  color: black !important;
}

.contact-email-label {
  margin-top: 30px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #383839;
  margin-bottom: 6.6px;
}

.contact-email {
  font-size: 15px;
  font-weight: 700;
}

.contact-close {
  position: absolute;
  top: 2.8px;
  right: 12.8px;
  font-size: 38px;
  font-weight: 500;
}

.contact-button-label {
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;
  text-align: left;
  color: #383839;
}

.pop-up-button-label {
  width: 178px;
  height: 21px;
  font-family: Lato;
  font-size: 17px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;
  text-align: left;
  color: inherit;
}

.service-item {
  border-left:unset;
  border-right:unset;
}

.header-bottom-radius {
  border-bottom-right-radius: unset;
  border-bottom-left-radius: unset;
}

.bill-info, .balance-info, .bill-info-footer {
  border-radius: unset;
  margin: 1px 0 1px 0;
  text-align: center;
  padding: 10px;
  padding-top: 4%;
  font-weight: 700;
  color: inherit;
}

.bill-info-footer {
  border-radius: 0px 0px 5px 5px;
}

.balance-info {
  height: auto;
}

.dashboard-section {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04);
  height: 97%;
}

.banner-section {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04);
  height: auto;
}

.account-section {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04);
  height: 95%;
}

.outstanding-balance {
  font-size: 21px;
  font-weight: 700;
  padding: 10px;
  margin-top: 3%;
}

.due-date {
  font-weight: 600;
  font-size: 12px;
}

.image-banner {
  height: auto;
  width: 100%;
}

.dashboard-card-header {
  background: #1C2949;
  color: white;
  border-radius: 5px 5px 0 0 !important;
  border-bottom: 1px solid #DADADA;
  padding: 14px;
  position: relative;
  margin-bottom: 0;
  font-size: 14px;
  text-align: center;
}

.guide-blogs-social {
  height: 484.8px;
  overflow-y: scroll;
}

.custom-indicator-item {
  background-clip: unset;
  border-top: unset;
  border-bottom: unset;
  border-radius: 50px;
  height: 15px;
  width: 15px;
}

.social-item {
  display: block;
  height: max-content;
  margin-bottom: 22.9px;
}

.social-img-container {
  height: 236.8px;
  width: 375.2px;
  background: cornflowerblue;
  object-fit: contain;
  border-radius: 5px;
}

.social-image {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 5px;
  margin: auto;
  background: lightgrey;
}

.social-image-caption {
  width: 100%;
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #383839;
  margin: auto;
  margin-top: 7.4px;
}

.social-img-label {
  display: block;
  width: 100%;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  padding: 10px 31px 10px 31px;
}

.list-group-flush {
  max-height: 100%;
  overflow-y: scroll;
}

.dd {
  text-align: justify;
}

.social-content {
  max-height: 444.8px;
  overflow-y: scroll;
}

.carousel-inner {
  border-radius:15px;
}

.service-summary-list {
  width: 70%;
  margin: 58px 0 0 81px
}

.service-summary-item {
  min-height: 244px;
  max-height: 244px; 
  overflow-y: scroll;
}

.summary-chart {
  height: auto;
  width: 100%;
}

.chart-container {
  height: 75%;
  width: 80%;
  position: absolute;
  top: 58px;
  left: 20px;
}

.my-services {
  height: 402.4px;
  overflow-y: scroll;
}

.blog-item {
  color: inherit;
}

.blog-item-content {
  height: fit-content;
}

.card-body.guide-blogs-social .list-group.list-group-flush .list-group-item.ng-star-inserted{
  padding: 15px;
}

.hardware-hub-body {
  height: 395.6px;
  background-color: #ffffff;
  padding: 27px;
}

.hub-image {
  width: 272px;
  height: 235px;
  object-fit: contain;
  margin: 38px 0 0 15%;
}

.hub-description {
  position: absolute;
  width: 52%;
  top: 70px;
  left: 49%;
}

.image-caption {
  width: 100%;
  height: auto;
  font-family: Lato;
  font-size: 21px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #1c2949;
  background: transparent;
  margin-bottom: 11px;
}

.hub-button {
  font-family: Lato;
  font-size: 13px !important;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: 0.07px;
  text-align: left;
  color: #ffffff !important;
  padding: 12px 28px 12px 28px !important;
  margin-top: 20px;
}

.register-to-shop {
  position: relative;
  left: 104px;
}

.product-title {
  font-family: Lato;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #383839;
  margin: auto;
}

.product-title.headset {
  width: 194px;
  height: 32px;
  font-size: 13px;
  line-height: 1.23;
  margin-top: 7px;
}

.product-title.phone {
  width: 200px;
  height: 32px;
  font-size: 13px;
  line-height: 1.23;
  margin-top: 7px;
}

.product-title.monitor {
  margin-top: 14px;
  width: 228px;
  height: 32px;
  font-size: 13px;
  line-height: 1.23;
}

.product-price {
  height: 25px;
  font-family: Lato;
  font-size: 21px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #383839;
  margin-top: 5px;
  margin: auto;
}

.middle-line-container {
  top: 86px;
  position: absolute;
  height: 67%;
  left: 45%;
  z-index: 2;
}

.horizontal-line-container {
  top: 5px;
  position: relative;
  z-index: 2;
  width: 100%;
  display: none;
}

.line-divider {
  background: #d0d6d9;
  height: 85%;
  width: 2px;
}

.hub-product-container {
  height: 307px; 
  background: #ffffff;
  width: 88%;        
  margin: auto;
}

.carousel-indicators li {
  border-top: unset;
  border-bottom: unset;
  height: 10px;
  width: 10px;
  background-color: #3e80b6
}

.carousel-indicators .active {
  background-color: #0086fc;
}

.input-icon-search{
  position: absolute;
  top: 6px;
  left: 15px;
  text-align: center;
  padding-top: 7px;
  color: #a39d9d;
}

.disable-section {
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
  background-color: rgba(28,41,73,0.8);
  border-radius: 5px;
}

.disable-label {
  font-family: Lato;
  font-size: 37px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 14%;
}

.learn-more-label {
  text-align: center;
  margin-top: 54px;
  width: 423px;
  height: 26px;
  font-family: Lato;
  font-size: 20px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  color: #383839;
  margin-bottom: 54px;
}

.other-services {
  position: relative;
  overflow: hidden;
  width: 300px;
  height: 201px;
  border-radius: 5px;
  box-shadow: 1px 2px 7px 0 rgba(0, 0, 0, 0.07);
}

.other-services:before {
    content: '';
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    transition: transform .5s ease-out;
    transform-origin: top right;
}

.other-services:hover::before {
  transform: scale(1.1);
  transform-origin: top right;
  filter: brightness(1.8);
}

.other-services.bps:before {
  background: url(../images/BPS.png) center center/auto no-repeat;
}

.other-services.la:before {
  background: url(../images/LA.png) center center/auto no-repeat;
}

.other-services.internet:before {
  background: url(../images/Internet.png) center center/auto no-repeat;
}

.other-services.inbound:before {
  background: url(../images/Inbound.png) center center/auto no-repeat;
}

.other-services.vo:before {
  background: url(../images/VO.png) center center/auto no-repeat;
}

.other-services-text {
  height: 60px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  position: relative;
  top: 134px;
  left: 17px;
  width: 225px;
  text-shadow: 1px 0 2px black;
}

div.col-lg-4.learn-more-item a:hover {
  text-decoration: unset;
}

.other-services-title {
  font-size: 15px;
  font-weight: 900;
}

.guides-blogs-section {
  flex: 0 0 35%;
  max-width: 35%;
}

.social-section {
  flex: 0 0 30%;
  max-width: 30%;
}

.carousel-inner.carousel-border {
  border-radius: 5px;
}

.carousel.slide.tablet, .carousel.slide.mobile,.carousel.slide.laptop{
  display: none !important;
}

.list-group.list-group-flush.faq { 
  height: 348.8px; 
  overflow-y: scroll;
}

#carouselHubIndicators.carousel.slide.hub { display: none; }

.carousel.slide.hub.small { display: none !important; }

.inbound-summary-chart {
  width: 100%;
  height: auto;
}

.top-number-fields {
  color: #0185fe;
  border-top: unset !important;
  border-bottom: unset !important;
}

.card-body.call-statistics-body {
  padding: 34px;
}

.total-history-container {
  height: 90%;
  width: 100%;
  position: relative;
  margin-top: 25px;
}

.break-down-pie-container {
  height: 250px; 
  width: 100%; 
  position: relative;
  margin-bottom: 15px;
}

.card-body.total-call-body {
  padding: 45.3px 93px 50px 86px;
}

.unique-callers-container {
  height: 234px;
  width: 100%;
  position: relative;
  margin-bottom: 63px;
}

.card-body.unique-callers-body {
  padding: 55px 40px 45px 40px;
}

.total-calls-day-container {
  height: 324.7px; 
  width: 100%; 
  position: relative;
}

.card-body.total-calls-week-body {
  padding: 45.3px 93px 50px 86px;
}

.total-calls-time-container {
  height: 400px; 
  width: 95%; 
  position: relative;
  margin: auto;
}

.total-calls-time-body {
  padding: 53px 108.9px 32.7px 109px;
}

.geo-chart-container
{
  width: 100%;
  height: 320px;
  position: relative;
  margin-bottom: 11px;
}

.answered-calls-container {
  height: 234px;
  width: 100%;
  position: relative;
  margin-bottom: 63px;
}

.card-body.answered-calls-body {
  padding: 55px 40px 45px 40px;
}

.card-body.summary-top-numbers-body {
  padding: 18px 47.3px 19.5px 59.4px;
}

.legend {
  width: 10px;
  height: 10px;
  display: inline-block;
}

.break-down-callers-bar {
  flex: 0 0 29.1666665%; max-width: 29.1666665%;
}

.break-down-callers-pie {
  flex: 0 0 29.1666665%; max-width: 29.1666665%;
}

.break-down-drop-down {
  height: 350px; margin-bottom: 5px;
}

.break-down-bar-graph {
  display: block;
  height: 10%;
  text-align: center;
  position: relative;
}

.break-down-bar-legend, .unique-callers-legend, .answered-calls-legend {
  display: inline-block;
  margin: 0px 20px; 
  font-size: 11px;
}


.break-down-pie-legend {
  display: block;
  margin: 0px 20px; 
  font-size: 11px
}

.local-legend {
  background-color: #0185fe;
}

.mobile-legend {
  background-color: #5944b0;
} 

.international-legend {
  background-color: #ff7626;
}

.missed-legend {
  background-color: #ffbb49;
}

.map-legend {
  background-color: #009ab5;
}

.break-down-pie-legend-container {
  display: block;
  position: relative;
  width: max-content;
  margin: auto;
}

.break-down-select {
  margin-bottom: 20px;
  width: 50%;
}

.break-down-bar-container {
  margin-bottom: 10px;
  height: 313px;
  margin-top: 10px;
}

.legend-container {
  display: inline;
  margin-right: 5px;
}

.legend-label {
  display: inline;
}

.total-calls-time-horizontal {
  display: none;
}

.call-stats-key {
  margin-left: 27px;
}

.call-stats-icon {
  position: absolute;
  margin-top: 3px;
}

.call-stats-key {
  margin-left: 27px;
}

.break-down-callers-geo {
  overflow: hidden;
}

.bar-legend-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.breakdown-map-legend-container.tablet-mobile {
  display: none;
}

.support-hub-icon {
  height: 15px;
  filter: brightness(50%);
  margin-right: 0.5rem;
  font-size: 1.15rem;
  width: 1.1em;
  text-align: center;
  vertical-align: middle;
}

/*========= MEDIA QUERIES ===========
===============START================= */

@media only screen and (min-width : 2560px){
  .image-banner { height: auto;width: auto;}
  .container-fluid { width: 65% !important;}
}

/*--- @LAPTOP LARGE---*/
@media only screen and (min-width : 1440px) and (max-width : 1599px){
  .image-banner { height: 250px;width: 100%; }
  .hub-image { margin: 30px 0 0 19px; }
  .hub-image-content {width: 175px; margin-top: 30px;}
  .hub-description {zoom: 85%;width: 63%;position: absolute;top: 90px;left: 45%;}
  .image-caption {margin-bottom: 24px}
  .line-divider {zoom: 76%;}
  .hub-product-container { width: 87%;zoom: 88%;}
  .hardware-hub-body { height: auto; }
  .social-image {width: 100%;height: auto;}
  .social-image-caption {width: 100%;font-size: small;text-align: left;}
  .list-group.my-services {width: auto;height: 384.4px;}
  .disable-label { font-size: 26px;}
  .chart-container { width: 100%;top: 0;left: 0;position: relative;margin-top: 35px; }
  .service-summary-list { width: 85%;margin: 20px 0 0 30px; }
  .service-detail { flex: 0 0 60%;max-width: 60%; }
  .service-config-button {flex: 0 0 40%;max-width: 40%;}
  .text-body { width: 75%; }
  .card-body.chart.services-list { padding: 10px; }
  span.badge.badge-success.service-badge.ml-3 { margin-left: unset !important; }
  .col-lg-8.service-detail .row strong.text-body.ml-3 { margin-left: unset !important; }
  .guides-blogs-section {flex: 0 0 33.33%;max-width: 33.33%;}
  .social-section {flex: 0 0 33.33%;max-width: 33.33%;}
  .register-to-shop { margin-top: 7px;left: 25%; }
  .middle-line-container { left: 46%;}
  .summary-chart {height: auto;width: 100%;}
  .pop-up-body {padding: 47px 42.9px 43px 46.3px;}
  #update_payment_details_popup .modal-content {width: 90%;}
  .break-down-pie-container {height: 221px;margin-bottom: 35px;margin-top: 20px;}
  .break-down-bar-legend, .unique-callers-legend, .answered-calls-legend {margin: 0px 7px;}
  .geo-chart-container {margin-top: 20px;}
  .break-down-bar-container {margin-bottom: 25px;height: 301px;margin-top: 20px;}
}

/*--- @LAPTOP LARGE---*/
@media only screen and (min-width : 1200px) and (max-width : 1439px){
  .image-banner { height: 250px;width: 100%; }
  .hub-image { margin: 30px 0 0 19px; }
  .hub-image-content {width: 175px; margin-top: 30px;}
  .hub-description {zoom: 85%;width: 63%;position: absolute;top: 90px;left: 45%;}
  .image-caption {font-size: 16px;margin-bottom: 22px;width: 100%;}
  .line-divider {zoom: 76%;}
  .hub-product-container {zoom: 78%;width: 91%;}
  .hardware-hub-body { height: auto; }
  .social-image {width: 100%;height: auto;}
  .social-image-caption {width: 100%;font-size: small;text-align: center;}
  .list-group.my-services {width: auto;height: 384.4px;}
  .disable-label { font-size: 26px;position: relative;top: 50%;left: unset;text-align: center;}
  .chart-container { width: 100%;top: 0;left: 0;position: relative;margin-top: 35px; }
  .service-summary-list { width: 85%;margin: 20px 0 0 30px; }
  .service-detail { flex: 0 0 60%;max-width: 60%; }
  .service-config-button {flex: 0 0 40%;max-width: 40%;}
  .text-body { width: 75%; }
  .card-body.chart.services-list { padding: 10px; }
  span.badge.badge-success.service-badge.ml-3 { margin-left: unset !important; }
  .col-lg-8.service-detail .row strong.text-body.ml-3 { margin-left: unset !important; }
  .guides-blogs-section {flex: 0 0 33.33%;max-width: 33.33%;}
  .social-section {flex: 0 0 33.33%;max-width: 33.33%;}
  .register-to-shop { margin-top: 7px;left: 25%; }
  .product-title.headset,.product-title.phone,.product-title.monitor { width: 100%;height: 52px;}
  .middle-line-container { left: 46%;}
  .col-lg-4.learn-more-item { flex: 0 0 33.333333%;max-width: 33.333333%; }
  .other-services { width: 100%; }
  .carousel.slide.web, .carousel.slide.mobile, .carousel.slide.tablet{display: none !important;}
  .carousel.slide.laptop{display: block !important;}
  .pop-up-body {padding: 57px 33.9px 53px 36.3px;}
  .card-body.total-call-body {padding: 28px 49.8px 24.1px 49px;}
  .geo-chart-container {margin: 0;top: 50%;-webkit-transform: translateY(-50%);transform: translateY(-50%);}
  .break-down-bar-legend {margin: 0px 6px;font-size: 11px;}
  .unique-callers-legend, .answered-calls-legend {margin: 0px 6px;font-size: 11px;margin-left: 25%;}
  .break-down-pie-container{height: 180px; margin-bottom: 20px;margin-top: 45px;}
  .custom-legend-container {margin: auto;text-align: left !important;width: max-content;}
  .break-down-callers-geo { flex: 0 0 33.333333%;max-width: 33.333333%; }
  .break-down-callers-bar { flex: 0 0 33.333333%;max-width: 33.333333%; }
  .break-down-callers-pie { flex: 0 0 33.333333%;max-width: 33.333333%; }
  .break-down-bar-container {margin-bottom: 15px;height: 285px;margin-top: 15px;}
}

/*---- @Large DEVICES | Ipads ----*/
@media only screen and (min-width : 992px) and (max-width : 1199px){
    .page-title{padding-left: 30px}
    .search-results-section h1{ padding-left: 15px;}
    .my-services-section {flex: 0 0 100%;max-width: 100%;}
    .services-summary-section {flex: 0 0 100%;max-width: 100%;}
    .summary-chart {padding-left: 42px; padding-right: 0;min-height: 443.094px;}
    .chart-container {display: block;width: 70%;position: relative;top: 50px;left: 25px;}
    .guides-blogs-section {flex: 0 0 100%;max-width: 100%;}
    .social-section {flex: 0 0 100%;max-width: 100%;}
    .hub-register { flex: 0 0 100%;max-width: 100%; }
    .middle-line-container { display: none; }
    .hub-featured-product { flex: 0 0 100%;max-width: 100%; }
    .hardware-hub-body { height: auto; }
    .campaign-banner { flex: 0 0 100%;max-width: 100%; }
    .current-account { flex: 0 0 100%;max-width: 100%; }
    .carousel.slide.web, .carousel.slide.mobile,.carousel.slide.tablet{display: none !important;}
    .carousel.slide.laptop{display: block !important;}
    .social-image { width: 50%; }
    .social-image-caption {width: 46%;}
    .outstanding-balance { margin-top: unset; }
    .bill-info, .balance-info, .bill-info-footer { padding: 23px; }
    .service-summary-list {width: 77%;margin: 24px 0 0 50px;}
    .hub-product-container {width: 100%;zoom: 90%;}
    .horizontal-line-container { display: block; }
    .col-lg-4.learn-more-item { flex: 0 0 50%;max-width: 50%; }
    .other-services { margin: auto; }
    #update_payment_details_popup .modal-content {width: 90%;}
    .pop-up-body {padding: 57px 20px 53px 20px;}
    .pop-up-title {margin-bottom: 0;}
    .call-statistics {flex: 0 0 100%;max-width: 100%;}
    .total-call-history {flex: 0 0 100%;max-width: 100%;}
    .total-calls-week {flex: 0 0 100%;max-width: 100%;}
    .unique-callers {flex: 0 0 100%;max-width: 100%;}
    .answered-calls {flex: 0 0 100%;max-width: 100%;}
    .summary-top-numbers {flex: 0 0 100%;max-width: 100%;}
    .break-down-callers-geo {flex: 0 0 90%; max-width: 90%;;margin-bottom: 30px;position: relative;margin: auto;}
    .break-down-callers-bar {flex: 0 0 99%; max-width: 99%;}
    .break-down-callers-pie {flex: 0 0 99%; max-width: 99%;;display: flex;}
    .break-down-callers-body {padding: 60px;height: auto;width: 100%;}
    .break-down-select { margin-bottom: 30px;width: 50%;}
    .break-down-bar-container { margin-bottom: 30px;height: 350px;width: 90%;margin: auto;}
    .bar-legend-container {margin-top: 30px;}
    .breakdown-map-legend-container {margin-bottom: 30px;}
    .break-down-pie-container {width: 50%;margin: 0 5%;}
    /* .unique-callers-body, .answered-calls-body {display: flex;} */
    .unique-callers-container, .answered-calls-container {width: 50%; margin: auto;position: relative;height: 250px;display: inline-block;}
    .custom-legend-container {margin: auto;text-align: left !important;position: relative;width: -webkit-max-content;width: -moz-max-content;width: max-content;padding-top: 106px;display: inline-block;height: 250px;flex: unset;max-width: unset;}
    .break-down-bar-legend {display: inline; width: 50%;}
    .unique-callers-legend, .answered-calls-legend {display: block; width: 100%;}
    .break-down-drop-down {height: unset;}
    .total-history-container { width: 80%;margin: auto;height: 324.7px;}
    .total-calls-day-container {width: 80%; margin: auto;}
    .total-calls-time-body {padding: 53px;height: auto;width: 100%;}
    .card-body.total-call-body {padding: 45px;height: auto;width: 100%;}
    .card-body.total-calls-week-body {padding: 35px;height: auto;width: 100%;}
    .legend { width: 12px;height: 12px;display: inline-block;}
    .unique-callers-legend, .answered-calls-legend {margin: 0px 8px;font-size: 12px;}
    .card-body.call-statistics-body {font-size: 12px;}
    .total-calls-time-horizontal {display: block;}
    .total-calls-time-vertical { display: none;}
    .card-body.answered-calls-body {display: inherit;position: relative;height: auto;width: 100%;}
    .card-body.unique-callers-body {display: inherit;position: relative;height: auto;width: 100%;}
    .total-calls-time-container {width: 80%}
    .geo-chart-container{width: 90%;position: relative;margin: auto;height: 300px;}
    .breakdown-map-legend-container.desktop{display: none;}
    .breakdown-map-legend-container.tablet-mobile {display: block;}
}

/*---- @MEDIUM DEVICES | TABLETS ----*/
@media only screen and (min-width : 768px) and (max-width : 991px){
.sidebar-left{ width: 255px;}
.main-wrapper{ max-width: 600px;}
.container.serv-department{max-width: 600px;}
.page-title{padding: 0 0 30px;}
.total-summary-holder .summary-actions button {  width: 100%;}

.service-controllers .serv-control-box section header p {width: auto; margin-right: 30px !important;}
.detail-row.form-group { padding: 30px 15px;}
.detail-row > .form-group:first-child { padding-bottom: 0;}
.detail-row > .form-group:last-child, .form-group:last-of-type{padding-top: 0;}
.detail-row > .form-group{padding:30px !important;border-bottom: 1px solid rgba(0, 0, 0, 0.04);     height: 110px;} 
.detail-row.form-group.fullwidth-border:before{content:none;}
.boiler-wrapper .container { max-width: 600px !important;}
.boiler-wrapper .header-form .button-col { justify-content: left;margin-top: 20px;}
.boiler-wrapper .header-form button.action-btn { position: absolute; top: -10px;}
.boiler-wrapper .header-form button.action-btn.action-save {right:0;}
.boiler-wrapper .header-form button.action-btn.action-delete {left:0; margin:0;}
.boiler-wrapper .flux-input, .boiler-wrap-group .flux-input{display:block;}
.boiler-wrapper .flux-input .col-lg-2.col-md-12, .boiler-wrap-group .flux-input .col-lg-2.col-md-12{height: 50px; border-top: 1px solid #dadada;}
.boiler-wrap-group input{width: 100%}
.serv-block{width:100%; max-width: 600px;}
.serv-block .serv-select-field.valign-middle{position: relative; transform: none; margin-top: 15px;max-width: 260px;}

.search-results-section .search-results-details .result-item div.col-lg-4.col-md-12{position: relative; padding-bottom: 15px; border-bottom: 1px solid #DADADA;}
.search-results-section .search-results-details .result-item div.col-lg-5.col-md-12.button-column{display: block; padding-top: 15px;}
.search-results-section .search-results-details .result-item .button-column div:last-child{ text-align: right;}
.search-results-section .search-results-details .result-item .button-column div:first-child{text-align: left;}
.search-results-section .search-results-details .result-item .status .action-btn{position:absolute; top:0; right:15px}
.search-results-section .pagination-holder{text-align: center}
.advance-config button.update-config {position: relative; bottom: 0;max-width: 450px;margin: 30px auto 0;     right: 0;}
.boiler-wrapper.advance-config .form-group.col-md-12:first-child{margin-bottom: 30px;}


.serv-block.advance-config .serv-config{    display: block;}
.serv-block.advance-config .serv-config .form-group.col-md-12:last-child {    padding-top: 25px;}

.summary-wrapper { max-width: 600px;  margin: 30px auto;}
.summary-wrapper .col-md-12:last-child {padding-top: 30px}
.carousel.slide.web, .carousel.slide.mobile, .carousel.slide.laptop{display: none !important;}
.carousel.slide.tablet{ display: block !important;}
.campaign-banner { flex: 0 0 100%;max-width: 100%; }
.current-account { flex: 0 0 100%;max-width: 100%; }
.outstanding-balance { margin-top: 0; }
.bill-info, .balance-info, .bill-info-footer { padding: 14px; }
.card-body.balance-info.balance { padding: 45px; }
.guides-blogs-section {flex: 0 0 100%;max-width: 100%;}
.social-section {flex: 0 0 100%;max-width: 100%;}
.my-services-section {flex: 0 0 100%;max-width: 100%;}
.guide-blogs-social.faq { padding-left: 50px; padding-right: 50px;height: unset; }
.list-group.list-group-flush.faq { height: 318px;;overflow-y: scroll; }
.social-image { width: 68%; }
.social-image-caption { width: 66%;font-size: 13px; }
.hub-image { width: unset;height: unset;object-fit: contain;margin: 13px 0 0 6%; }
.hub-image-content { height: auto;width: 100%;position: relative; }
.hub-description {position: absolute;width: 75%;top: 10%;left: 44%;}
.image-caption {width: 65%;font-size: 120%;text-align: left;background: transparent;margin-bottom: 11px;}
.register-to-shop { left: 20%;margin-top: 3%;zoom: 90%; }
.middle-line-container { display: none; }
.horizontal-line-container { display: block;margin-top: 50px;margin-bottom: 30px;}
.hardware-hub-body { height: unset; }
.hub-register-content { height: unset !important; }
.row.hub-product-container.web { display: none; }
#carouselHubIndicators.carousel.slide.hub { display: block; }
.hub-product-container { height: 343px; }
.text-center.headset-phone { flex: 0 0 50%;max-width: 50%;zoom: 85%;}
.text-center.monitor { zoom: 85%; }
.chart-container {height: 207px;position: relative;top: 0;left: 10%;margin-top: 20px;margin-bottom: 20px;}
.summary-chart { height: 100%; }
.service-summary-list {width: 77%;margin: 24px 0 0 13%;}
.dashboard-section { height: auto; }
.other-services { margin: auto; }
.list-group.service-summary-item { display: none;}
.pop-up-body {padding: 57px 20px 53px 20px;}
.pop-up-title {margin-bottom: 0;}
.call-statistics {flex: 0 0 100%;max-width: 100%;}
.total-call-history {flex: 0 0 100%;max-width: 100%;}
.total-calls-week {flex: 0 0 100%;max-width: 100%;}
.unique-callers {flex: 0 0 100%;max-width: 100%;}
.answered-calls {flex: 0 0 100%;max-width: 100%;}
.summary-top-numbers {flex: 0 0 100%;max-width: 100%;}
.break-down-callers-geo {flex: 0 0 100%; max-width: 100%;margin-bottom: 30px;position: relative;margin: auto;}
.break-down-callers-bar {flex: 0 0 99%; max-width: 99%;}
.break-down-callers-pie {flex: 0 0 99%; max-width: 99%;;display: inherit;}
.break-down-callers-body {padding: 30px;height: auto;width: 100%;}
.break-down-select { margin-bottom: 30px;width: 50%;}
.break-down-bar-container { margin-bottom: 30px;height: 350px;width: 90%;margin: auto;}
.bar-legend-container {margin-top: 6%;}
.breakdown-map-legend-container {margin-bottom: 6%;}
.break-down-pie-container {width: 50%;margin: 0 5%;height: 200px;}
/* .unique-callers-body, .answered-calls-body {display: flex;} */
.unique-callers-container, .answered-calls-container {width: 50%; margin: auto;position: relative;height: 250px;display: inline-block;}
.custom-legend-container {margin: auto;text-align: left !important;position: relative;width: -webkit-max-content;width: -moz-max-content;width: max-content;padding-top: 106px;display: inline-block;height: 250px;flex: unset;max-width: unset;}
.break-down-bar-legend {display: inline; width: 50%;}
.unique-callers-legend, .answered-calls-legend {display: block; width: 100%;}
.break-down-drop-down {height: unset;}
.total-history-container { width: 80%;margin: auto;height: 324.7px;}
.total-calls-day-container {width: 80%; margin: auto;}
.total-calls-time-body {padding: 53px;height: auto;width: 100%;}
.card-body.total-call-body {padding: 45px;height: auto;width: 100%;}
.card-body.total-calls-week-body {padding: 35px;height: auto;width: 100%;}
.legend { width: 12px;height: 12px;display: inline-block;}
.unique-callers-legend, .answered-calls-legend {margin: 0px 8px;font-size: 12px;}
.card-body.call-statistics-body {font-size: 12px;padding: 25px;height: auto;width: 100%;}
.total-calls-time-horizontal {display: block;}
.total-calls-time-vertical { display: none;}
.break-down-pie-legend {margin: unset;}
.card-body.answered-calls-body {display: inherit;position: relative;height: auto;width: 100%;}
.card-body.unique-callers-body {display: inherit;position: relative;height: auto;width: 100%;}
.total-calls-time-container {width: 80%}
.geo-chart-container{width: 90%;position: relative;margin: auto;height: 260px;}
.breakdown-map-legend-container.desktop{display: none;}
.breakdown-map-legend-container.tablet-mobile {display: block;}
}

/*--- @SMALL DEVICES ---*/
@media only screen and (max-width: 767px){
	
.row.inside-row .sorty-row{padding-left:15px !important;padding-right:15px !important;}
.search-results-section .card-body label{margin:0 !important}
.page-header { margin: 0 0 20px;}

.inner-wrapper{margin-top: 0;}
.header .header-right{margin-top: 57px !important;     position: relative; border-top: 1px solid #dedede;height:72px;}
.header-right .notifications{float:none !important;    margin: 16px 0 0 8px;}
.header-right .notifications button{font-size: 12px; padding: 8px 8px;}
.header-right .notifications .custom-select{font-size: 12px; padding: 8px 25px 8px 8px; height:calc(2.12rem + 2px);}
.header-right .userbox{float: none; width: auto; margin: 0; right: 20px; position: absolute; top: 15px;}
.header-right .userbox .profile-info { margin: 0;}
.header-right .userbox .role {max-width: none;    margin-right: 8px;}
.header-right .userbox .name{ max-width: none;}

.modal-content-slide .modal-content-wrap{padding:0;}
.total-summary-holder .summary-actions button { width: 100%;}

.main-wrapper{max-width: 330px;     margin: 0 auto;}
.detail-row.form-group{padding:0;}
.detail-row > .form-group:first-child { padding-bottom: 0; margin:0;}
.detail-row > .form-group:last-child, .form-group:last-of-type{padding-top: 0;}
.detail-row > .form-group{padding:30px !important;border-bottom: 1px solid rgba(0, 0, 0, 0.04);} 
.detail-row.form-group.fullwidth-border:before{content:none;}
.search-results-section .search-results-details .result-item{padding:15px 0;}
.search-results-section .search-results-details .result-item .button-column{justify-content: center;display: block; padding-top: 30px;}
.search-results-section .search-results-details .result-item .button-column div{    padding-left: 4px;padding-right: 4px; flex: 0 0 50%;}
.search-results-section .search-results-details .result-item .button-column div:first-child{text-align: left;}
.search-results-section .search-results-details .result-item .button-column div:last-child{text-align: right;}
.search-results-section .search-results-details .result-item .button-column a{    width: 117px;  font-size: 13px;}
.search-results-section .pagination-holder{text-align:center;}
.boiler-wrapper .container { max-width: 330px;padding: 20px 15px;}
.boiler-wrapper.additional-info .container .form-group:last-of-type button{display: block;
margin: 30px auto 0;}
.boiler-wrapper .header-form .button-col { justify-content: left;margin-top: 20px;}
.boiler-wrapper .header-form button.action-btn { position: absolute; top: -10px;}
.boiler-wrapper .header-form button.action-btn.action-save {right:0;}
.boiler-wrapper .header-form button.action-btn.action-delete {left:0; margin:0;}
.boiler-wrapper .flux-input, .boiler-wrap-group .flux-input{display:block;}
.boiler-wrapper .flux-input .col-lg-2.col-md-12, .boiler-wrap-group .flux-input .col-lg-2.col-md-12{height: 50px; border-top: 1px solid #dadada;}
.boiler-wrap-group input{width: 100%}
.container.serv-department{max-width: 330px;}
.serv-department .form-group:last-child button{display: block;
position: relative; right: unset; margin: 15px auto 0; max-width: 270px; width: 100%;}
.serv-department .action-group button.action-btn.action-delete{margin: 0;    text-align: left;}
.serv-block{width:100%; max-width: 330px;}
.serv-block .serv-select-field.valign-middle{position: relative; transform: none; margin-top: 15px;max-width: 260px;}
.serv-block .serv-miniblock{padding:20px 0;}
.serv-select-field label { margin: 0 9px 0 0;}
.flux-field-group input[type="text"]{width:60%;}
.advance-config button.update-config {position: relative; bottom: 0;max-width: 450px;margin: 30px auto 0;     right: 0;}
.boiler-wrapper.advance-config .form-group.col-md-12:first-child{margin-bottom: 30px;}
.boiler-wrapper .nav-tabs li .nav-link{font-size: 12px; padding:10px;}

.myalltel-card-wrapper .card-subbody.ng-star-inserted .input-group > .form-control:not(:first-child){margin:7px 10px;}

.serv-block.advance-config .serv-config{display: block;padding: 30px 7px;}
.serv-block.advance-config .serv-config .form-group.col-md-12:last-child {padding-top: 25px;}

html.csstransforms .sidebar-left{margin-left: 0;}
.inner-wrapper .sidebar-left{-webkit-transition: 0.8s ease !important; transition: 0.8s ease !important;}
.inner-wrapper.sidebar-left-opened .sidebar-left{ left: 0}
.sidebar-left .sidebar-header .sidebar-title{color:#777;}
.contact-content {width:359px;}
.contact-title {width: 100%;}
.contact-header-message {width: 100%;}
.contact-number {width: 100%;}
.contact-chat {width: 100%;}
.carousel.slide.web, .carousel.slide.tablet, .carousel.slide.laptop{display: none !important;}
.carousel.slide.mobile{ display: block !important;}
.guides-blogs-section {flex: 0 0 100%;max-width: 100%;}
.social-section {flex: 0 0 100%;max-width: 100%;}
.social-image-caption { width: 66%;font-size: 13px; }
.hub-image { width: unset;height: unset;object-fit: contain;margin: 13px 0 0 6%; }
.hub-image-content { height: auto;width: 100%;position: relative; }
.hub-description {position: absolute;width: 75%;top: 10%;left: 44%;}
.image-caption {width: 65%;font-size: 155%;text-align: left;background: transparent;margin-bottom: 8px;line-height: 1.13;zoom:69% }
.register-to-shop { left: 8%;margin-top: 3%;zoom: 90%; }
.middle-line-container { display: none; }
.horizontal-line-container { display: block;margin-top: 50px;margin-bottom: 30px;}
.hardware-hub-body { height: unset; }
.hub-register-content { height: unset !important; }
.row.hub-product-container.web { display: none; }
#carouselHubIndicators.carousel.slide.hub { display: block; }
.hub-product-container { height: 343px; }
.text-center.headset-phone { flex: 0 0 50%;max-width: 50%;zoom: 85%;}
.text-center.monitor { zoom: 85%; }
.chart-container {height: 207px;position: relative;top: 0;left: 10%;margin-top: 20px;margin-bottom: 20px;}
.summary-chart { height: 100%; }
.service-summary-list {width: 77%;margin: 24px 0 0 13%;}
.dashboard-section { height: auto; }
.social-image { width: 68%; }
.social-image-caption { width: 66%;font-size: 13px; }
.product-title.headset,.product-title.phone {width: 141px; height: 75px;}
.product-title.monitor { height: 62px;margin-top: 25px;}
.other-services {width: 100%;height: 159px;}
.other-services-text { top: 94px }
.disable-label { font-size: 18px;left: 4%;text-align: center;}
.list-group.service-summary-item { display: none;}
.pop-up-title {width: 230.2px;height: 42px;}
.pop-up-body {padding: 61.3px 24.6px 63px 25px;}
.call-statistics {flex: 0 0 100%;max-width: 100%;}
.total-call-history {flex: 0 0 100%;max-width: 100%;}
.total-calls-week {flex: 0 0 100%;max-width: 100%;}
.unique-callers {flex: 0 0 100%;max-width: 100%;}
.answered-calls {flex: 0 0 100%;max-width: 100%;}
.summary-top-numbers {flex: 0 0 100%;max-width: 100%;}
.break-down-callers-geo {display: none;}
.break-down-callers-bar {flex: 0 0 100%;max-width: 100%;}
.break-down-callers-pie {flex: 0 0 100%;max-width: 100%;display: flex;}
.break-down-callers-body {padding: 5%;}
.break-down-select { margin-bottom: 30px;width: 50%;}
.break-down-bar-container { margin-bottom: 30px;height: 300px;width: 80%;margin: auto;}
.bar-legend-container {margin-top: 4%;}
.breakdown-map-legend-container {margin-bottom: 4%;}
.break-down-pie-container {width: 50%;margin: 0 5%;height: 200px;}
.unique-callers-body, .answered-calls-body {display: flex;}
.unique-callers-container, .answered-calls-container {width: 50%; margin: auto;position: relative;height: 176px;}
.custom-legend-container {margin: auto;text-align: left !important;flex: unset; max-width: unset;display: block;position: relative;width: -webkit-max-content;width: -moz-max-content;width: max-content;padding-left: 15%;}
.break-down-bar-legend {display: inline; width: 50%;}
.unique-callers-legend, .answered-calls-legend {display: block; width: 100%;}
.break-down-drop-down {height: unset;}
.total-history-container { width: 99%;margin: auto;height: 324.7px}
.total-calls-day-container {width: 99%; margin: auto;}
.total-calls-day-container {width: 99%; margin: auto;}
.total-calls-time-body {padding: 20px}
.card-body.total-call-body {padding: 45px;}
.card-body.total-calls-week-body {padding: 35px}
.legend { width: 12px;height: 12px;display: inline-block;}
.unique-callers-legend, .answered-calls-legend {margin: 0px 8px;font-size: 12px;display: inline-flex;}
.card-body.call-statistics-body {font-size: 12px;padding: 15px;}
.card-body.summary-top-numbers-body {padding: 1%;}
.legend-container {width: max-content;display: inline;height: 100%;margin-right: 5px;margin-top: 2px;}
.break-down-select { margin: auto;width: 65%;}
.total-calls-time-horizontal {display: block;}
.total-calls-time-vertical { display: none;}
.call-stats-body{ font-size: 15px;}
.call-stats-cols { width: 40%; }
.break-down-bar-legend, .unique-callers-legend, .answered-calls-legend {margin: 5px 3%;}
.break-down-pie-legend {margin: unset;}
.breakdown-map-legend-container.desktop{display: none;}
.breakdown-map-legend-container.tablet-mobile {display: block;}
}
/*--- @SMALL DEVICES ---*/
@media only screen and (max-width: 424px) {
  .register-to-shop { left: 13%;margin-top: 5%;zoom: 94%; }
  .hub-description { top: 10%;left: 44%;zoom: 85%; }
  .product-title.headset,.product-title.phone {width: 141px; height: 75px;}
  .product-title.monitor { height: 62px;margin-top: 25px;}
  .hub-product-container { width: 97%; }
  .text-body { width: 278px; }
  .list-group.service-summary-item { display: none;}
  .pop-up-chat { width: 100%; height: auto; }
  .card-body.total-call-body {padding: 16px;}
  .card-body.total-calls-week-body {padding: 22px}
  .description { display: none; }
  .card-body.unique-callers-body {padding: 15px;}
  .card-body.answered-calls-body{padding: 15px;}
  .break-down-pie-container{height: 150px;}
  .break-down-bar-legend {width: unset;}
}

/*--- @EXTRA SMALL DEVICES ---*/
@media only screen and (max-width: 374px) {
.main-wrapper { max-width: 300px;}
.search-results-section .search-results-details .result-item .button-column a {width: 110px;font-size: 12px;}
.boiler-wrapper .nav-tabs li .nav-link{font-size: 11px}
.search-results-section h1{margin-left: 15px}
.page-title{padding-left: 15px;}
.header-right .userbox .role {max-width: 60px; margin-right: 8px;}
.header-right .userbox {right:10px;}
.contact-content {width:359px;}
.contact-title {width: 100%;}
.contact-header-message {width: 100%;}
.contact-number {width: 100%;}
.contact-chat {width: 100%;}
.carousel.slide.web, .carousel.slide.tablet, .carousel.slide.laptop{display: none !important;}
.carousel.slide.mobile{ display: block !important;}
.guides-blogs-section {flex: 0 0 100%;max-width: 100%;}
.social-section {flex: 0 0 100%;max-width: 100%;}
.register-to-shop { left: 4%;margin-top: 5%;zoom: 94%; }
.text-body { width: 178px; }
.carousel.slide.hub { display: none !important; }
.carousel.slide.hub.small { display: block !important; }
.list-group.service-summary-item { display: none;}
.pop-up-message {width: 245.2px;height: 76px;}
}
/*========= MEDIA QUERIES ===========*/